import React from 'react'
import Link from 'gatsby-link'
import { navigateTo } from 'gatsby-link'
import coverImg from './../../assets/images/cover-img-2.png'
import cCircles from './../../assets/images/toc-condensed-circles.png'
import mastImg from './../../assets/images/masthead-2.png'
import { Divider} from 'semantic-ui-react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import './style.css'

class TOC extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          splashVisible: true,
          sketchMount: true
      }
    //   this.hideSplash = this.hideSplash.bind(this)
    //   this.destroySplashElement = this.destroySplashElement.bind(this)

    }

    // hideSplash() {
    //     console.log('hi')
    //     this.setState({
    //         splashVisible: false,
    //     }, () => {
    //         setTimeout(this.destroySplashElement, 3000);
    //     })
    // }

    componentDidMount() {
        console.log(this.props.location.splashStatus)
        if (this.props.location.spashStatus == false) {
            this.setState({sketchMount: false})
        }

    }

    destroySplashElement() {
        console.log('turding')
        this.setState({sketchMount: false}, ()=> {
            console.log(this.state)
        })
    }

    render() {
        return (
            <div className="home-page">
            <img className="toc-cover-lines" src={coverImg} />
                      <div className="home-item-container home-item-1">
                      <Link to='/current-state-of-death'>
                        <div className="toc-number">1</div>
                        <div className="toc-type">The Current State of Death</div>
                        </Link>
                      </div>
                     
                      <div className="home-item-container home-item-3">
                      <Link to='/research'>
                        <div className="toc-number">2</div>
                        <div className="toc-type">Research: Signals, Drivers, Trends, & Interviews</div>
                     </Link>
                      </div>
                      <div className="home-item-container home-item-2">
                        <Link to='/scenarios'>
                            <div className="toc-number">3</div>
                            <div className="toc-type">Scenarios</div> 
                        
                        </Link>
                      </div>
                      {/* <div className="home-item-container home-item-4">
                      <Link to='/futures/Transformative'><div className="toc-type">Scenario Transformation:</div> Dust, Soil, & Community Death Spaces</Link>
                      </div> */}
                      {/* <div className="home-item-container home-item-5">
                      <div className="coming-soon">Coming Soon</div><div className="toc-type">Animated Short:</div>  The Brain & the Shell
                      </div> */}
                      {/* <div className="home-item-container home-item-6">
                      <Link to='/research/interviews'><div className="toc-type">Interview:</div> Death in the Emergency Room</Link>
                      </div> */}
                      {/* <div className="home-item-container home-item-7">
                        <div className="coming-soon">Coming Soon</div><div className="toc-type">Interview:</div>Saying Goodbye with a Death Doula
                      </div> */}
                      {/* <div className="home-item-container home-item-8">
                       <Link to='/drivers'><div className="toc-type">Knowledge Boards:</div>  What's Driving Change in the Death Space</Link>
                      </div> */}
                      <div className="home-item-container home-item-9">
                        <div className="mast-head" onClick={ () => navigateTo('/')}>
                                <img src={mastImg} />
                         </div>
                       </div> 
                      <div className="home-item-container home-item-10">
                        <Link to="/options">
                            <img className="c-circles" src={cCircles} />
                            <div className="toc-number">4</div>
                            <div className="toc-type">  ACTION: How do we stop the loop?</div>
                        </Link>
                      </div>
                      <div className="home-item-container about-link"><Link to="/about">About this website</Link></div>
            </div>
        )
    }
}

export default TOC
